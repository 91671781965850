import "./ChatInputForm.css";
import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { motion } from "framer-motion";

const ChatInputForm = ({
  input,
  setInput,
  handleSubmit,
  isLoading,
  user,
}) => {

  return (
    <motion.div
      // fades in
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
      className="chat-input-box"
    >
      {/* Only show chat input if user is signed in */}
      {user && (
        <>

          {/* Input form */}
          <form data-testid="chat-input-form" onSubmit={handleSubmit}>
            <textarea
              maxLength="1000" // Adjust this as needed
              required={true}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  if (e.target.value.trim() === "") return;
                  if (isLoading) return;
                  handleSubmit(e);
                }
              }}
              data-testid="chat-input-textarea"
              className="chat-input-textarea"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              // Change placeholder based on selected model
              placeholder="Write question here"
            ></textarea>

            {/* Submit button */}
            <button
              data-testid="submit-button"
              className="submit-button"
              disabled={isLoading}
              aria-label="submit"
            >
              {/* Submit svg */}
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 20 20"
                className="submit-svg"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.894 2.553a1 1 0 0 0-1.788 0l-7 14a1 1 0 0 0 1.169 1.409l5-1.429A1 1 0 0 0 9 15.571V11a1 1 0 1 1 2 0v4.571a1 1 0 0 0 .725.962l5 1.428a1 1 0 0 0 1.17-1.408l-7-14z"
                  stroke="none"
                />
              </svg>
            </button>
          </form>
        </>
      )}
    </motion.div>
  );
};

export default ChatInputForm;
