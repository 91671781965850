import React from "react";
import "./Disclaimer.css";

function Disclaimer() {
  return (
    <div className="disclaimer-container">
      <p className="disclaimer-text">
        iovaGPTv0.0.1 Provided by Aayhova Technologies Pvt Ltd
      </p>
    </div>
  );
}

export default Disclaimer;
