import React, { useState } from "react";
import "./SuggestionButton.css";
import "./SuggestionModal.css";
import Modal from "react-overlays/Modal";
import styled, { css } from "styled-components";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

function SuggestionButton({ user }) {

  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");

  // Create the backdrop component for the modal
  const renderBackdrop = (props) => <Backdrop {...props} />;

  // Connect to the firebase database
  //const chatRef = ref(db, "bugs");

  function handleSubmit() {
    console.log(`${input}`);
    // Push the bug to database with a timestamp
    // push(chatRef, {
    //   bug: input,
    //   timestamp: Date.now(),
    // })
    //   .then(() => {
    //     console.log("Bug Submitted");
    //     setInput("");
    //   })
    //   .catch((error) => {
    //     console.log("The write failed...", error);
    //   });
  }

  return (
    // <div className="store-button-container">
    //   {/* Only display if user is logged in */}
    //   {user && (
    //     <Link to={"/store"} className="store-link">
    //       {/* Store basket svg */}
    //       {/* <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         viewBox="0 0 576 512"
    //         className="store-button-svg"
    //         style={{ width: 23, height: 23, marginRight: 10 }}
    //       >
    //         <path
    //           d="M171.7 191.1h232.6L322.7 35.07c-6.1-11.76-1.5-26.249 10.2-32.364 11.8-6.115 26.3-1.539 32.4 10.224l93.1 178.17H544c17.7 0 32 15.2 32 32 0 18.6-14.3 32-32 32l-51.9 208.4c-8 28.5-32.7 48.5-62.1 48.5H145.1c-28.5 0-54.1-20-61.22-48.5L32 255.1c-17.67 0-32-13.4-32-32 0-16.8 14.33-32 32-32h85.6l93.1-178.17c6.1-11.763 20.6-16.339 32.4-10.224 11.7 6.115 16.3 20.604 10.2 32.364L171.7 191.1zm19.4 112c0-8-6.3-16-16-16-7.9 0-16 8-16 16v96c0 9.7 8.1 16 16 16 9.7 0 16-6.3 16-16v-96zm80 0v96c0 9.7 8.1 16 16 16 9.7 0 16.9-6.3 16.9-16v-96c0-8-7.2-16-16.9-16-7.9 0-16 8-16 16zm144.9 0c0-8-7.2-16-16-16s-16 8-16 16v96c0 9.7 7.2 16 16 16s16-6.3 16-16v-96z"
    //           fill="#fff"
    //         />
    //       </svg> */}
    //       <svg class="svg-icon" viewBox="0 0 20 20">
		// 					<path d="M14.999,8.543c0,0.229-0.188,0.417-0.416,0.417H5.417C5.187,8.959,5,8.772,5,8.543s0.188-0.417,0.417-0.417h9.167C14.812,8.126,14.999,8.314,14.999,8.543 M12.037,10.213H5.417C5.187,10.213,5,10.4,5,10.63c0,0.229,0.188,0.416,0.417,0.416h6.621c0.229,0,0.416-0.188,0.416-0.416C12.453,10.4,12.266,10.213,12.037,10.213 M14.583,6.046H5.417C5.187,6.046,5,6.233,5,6.463c0,0.229,0.188,0.417,0.417,0.417h9.167c0.229,0,0.416-0.188,0.416-0.417C14.999,6.233,14.812,6.046,14.583,6.046 M17.916,3.542v10c0,0.229-0.188,0.417-0.417,0.417H9.373l-2.829,2.796c-0.117,0.116-0.71,0.297-0.71-0.296v-2.5H2.5c-0.229,0-0.417-0.188-0.417-0.417v-10c0-0.229,0.188-0.417,0.417-0.417h15C17.729,3.126,17.916,3.313,17.916,3.542 M17.083,3.959H2.917v9.167H6.25c0.229,0,0.417,0.187,0.417,0.416v1.919l2.242-2.215c0.079-0.077,0.184-0.12,0.294-0.12h7.881V3.959z"></path>
		// 				</svg>
    //       Suggestion 
    //     </Link>
    //   )}
    // </div>

    <div className="modal-example">
      {/* Open modal button */}
      <div className="store-button-container">
        {user && (
          <button
            type="button"
            className="store-link"
            onClick={() => setShow(true)}
          >
            {/* <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="bug-svg"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
            </svg> */}

            <svg className="svg-icon" viewBox="0 0 20 20">
              <path d="M14.999,8.543c0,0.229-0.188,0.417-0.416,0.417H5.417C5.187,8.959,5,8.772,5,8.543s0.188-0.417,0.417-0.417h9.167C14.812,8.126,14.999,8.314,14.999,8.543 M12.037,10.213H5.417C5.187,10.213,5,10.4,5,10.63c0,0.229,0.188,0.416,0.417,0.416h6.621c0.229,0,0.416-0.188,0.416-0.416C12.453,10.4,12.266,10.213,12.037,10.213 M14.583,6.046H5.417C5.187,6.046,5,6.233,5,6.463c0,0.229,0.188,0.417,0.417,0.417h9.167c0.229,0,0.416-0.188,0.416-0.417C14.999,6.233,14.812,6.046,14.583,6.046 M17.916,3.542v10c0,0.229-0.188,0.417-0.417,0.417H9.373l-2.829,2.796c-0.117,0.116-0.71,0.297-0.71-0.296v-2.5H2.5c-0.229,0-0.417-0.188-0.417-0.417v-10c0-0.229,0.188-0.417,0.417-0.417h15C17.729,3.126,17.916,3.313,17.916,3.542 M17.083,3.959H2.917v9.167H6.25c0.229,0,0.417,0.187,0.417,0.416v1.919l2.242-2.215c0.079-0.077,0.184-0.12,0.294-0.12h7.881V3.959z"></path>
            </svg>
            Suggestion
          </button>
        )}
      </div>
      

      {/* The Modal component */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
        className="modal"
      >
        <div className="modal-inner-container">
          {/* Modal label */}
          <h3 className="modal-label">Your Valuable Suggestion</h3>
          {/* Introduction to bug report */}
          <p>
            If you have stumbled onto an idea that you think can enhance your experiance of using this tool, We will be happy to introduce that and improve your ease.
          </p>

          {/* Tips for submitting a bug report */}
          <ul className="tips-for-submitting">
            <li>Be specific about the information that you were looking for.</li>
            <li>
              Avoid typos or abbreviations, which make difficult for us to understand.
            </li>
            <li>
              Include refrence for document/book name, any input that you think can give mlore relevant answers.
            </li>
          </ul>

          {/* The bug report form */}
          <form onSubmit={handleSubmit} className="bug-form">
            <textarea
              className="bug-form-textarea"
              maxLength="3000"
              required={true}
              placeholder="Your Suggestion"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            ></textarea>
            <button className="submit-bug-button">Submit</button>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default SuggestionButton;
