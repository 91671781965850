import Modal from "react-overlays/Modal";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import "./SuggestionModal.css";
//import { ref, push } from "firebase/database";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

function SuggestionModal({ user }) {
  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");

  // Create the backdrop component for the modal
  const renderBackdrop = (props) => <Backdrop {...props} />;

  // Connect to the firebase database
  //const chatRef = ref(db, "bugs");

  function handleSubmit() {
    console.log(`${input}`);
    // Push the bug to database with a timestamp
    // push(chatRef, {
    //   bug: input,
    //   timestamp: Date.now(),
    // })
    //   .then(() => {
    //     console.log("Bug Submitted");
    //     setInput("");
    //   })
    //   .catch((error) => {
    //     console.log("The write failed...", error);
    //   });

    fetch(
      "https://api.iova.app/api/llm/addSuggestion",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          emailId: user.email,
          suggestion: input
        }),
      }
    )
    .then(resp =>resp.json())
    .then(data => {
      console.log(data);
    })
    .catch((error) => {
      // Show response failed error
      console.log(error);
    });
  }

  return (
    <div className="modal-example">
      {/* Open modal button */}
      {user && (
        <button
          type="button"
          className="open-modal-button"
          onClick={() => setShow(true)}
        >
          {/* <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={2}
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="bug-svg"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
          </svg> */}
          <svg className="svg-icon" viewBox="0 0 20 20">
            <path d="M14.999,8.543c0,0.229-0.188,0.417-0.416,0.417H5.417C5.187,8.959,5,8.772,5,8.543s0.188-0.417,0.417-0.417h9.167C14.812,8.126,14.999,8.314,14.999,8.543 M12.037,10.213H5.417C5.187,10.213,5,10.4,5,10.63c0,0.229,0.188,0.416,0.417,0.416h6.621c0.229,0,0.416-0.188,0.416-0.416C12.453,10.4,12.266,10.213,12.037,10.213 M14.583,6.046H5.417C5.187,6.046,5,6.233,5,6.463c0,0.229,0.188,0.417,0.417,0.417h9.167c0.229,0,0.416-0.188,0.416-0.417C14.999,6.233,14.812,6.046,14.583,6.046 M17.916,3.542v10c0,0.229-0.188,0.417-0.417,0.417H9.373l-2.829,2.796c-0.117,0.116-0.71,0.297-0.71-0.296v-2.5H2.5c-0.229,0-0.417-0.188-0.417-0.417v-10c0-0.229,0.188-0.417,0.417-0.417h15C17.729,3.126,17.916,3.313,17.916,3.542 M17.083,3.959H2.917v9.167H6.25c0.229,0,0.417,0.187,0.417,0.416v1.919l2.242-2.215c0.079-0.077,0.184-0.12,0.294-0.12h7.881V3.959z"></path>
          </svg>
          Suggestion
        </button>
      )}

      {/* The Modal component */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
        className="modal"
      >
        <div className="modal-inner-container">
          {/* Modal label */}
          <h3 className="modal-label">Report Bug</h3>
          {/* Introduction to bug report */}
          <p style={{width: "fit-content", textAlign:'left'}}>
            If you have an idea that you think can enhance the experiance of using this tool, 
            we will be happy to introduce that and improve your experiance. 
            It can be something that would help you in everyday task or something about the current tool that you think should be changed.
          </p>

          {/* Tips for submitting a bug report */}
          {/*<ul className="tips-for-submitting">
            <li>Be specific about every step that leads to the problem.</li>
            <li>
              Avoid typos or abbreviations, which make bugs much harder to track
              down.
            </li>
            <li>
              Include how long you have been seeing the problem, and how often
              (if more than once).
            </li>
        </ul>*/}

          {/* The bug report form */}
          <form onSubmit={handleSubmit} className="bug-form">
            <textarea
              className="bug-form-textarea"
              maxLength="3000"
              required={true}
              placeholder="Write the suggestion"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            ></textarea>
            <button className="submit-bug-button">Submit</button>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default SuggestionModal;
