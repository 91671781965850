import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import { CircularProgress } from "@mui/material";
import { initializeApp } from "firebase/app";
const Error404Page = React.lazy(() => import("./Pages/Error404Page"));

const firebaseConfig = {
  apiKey: "AIzaSyC_nabaXreDDMdGLP3rRp_eVQ4-6zDJXMs",
  authDomain: "llmauthentication.firebaseapp.com",
  projectId: "llmauthentication",
  storageBucket: "llmauthentication.appspot.com",
  messagingSenderId: "658695548592",
  appId: "1:658695548592:web:3a6d28bb22567e9c93d575"
};

const app = initializeApp(firebaseConfig);

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <CircularProgress
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "#b3befe",
            }}
          />
        }
      >
        <Routes>
          {/* <Route path="/" element={<MainPage app={app} />} />

          <Route path="/store" element={<StorePage app={app} />} />

          <Route path="*" element={<Error404Page />} /> */}
          <Route path="/" element={<MainPage app={app} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
