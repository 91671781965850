import React from "react";
import "./Hero.css";
import HeroInfo from "./HeroInfo";

function Hero() {
  return (
    <div className="hero-container">
      {/* Hero heading */}
      <h1 className="hero-heading">iovaGPT</h1>

      <div className="info-container">
        {/* Section for Examples */}
        <div className="info-sub-container side-container">
          <div className="info-heading">
            {/* SVG icon for Examples */}
            <svg className = "hero-svg" width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 5.00005C7.01165 5.00082 6.49359 5.01338 6.09202 5.21799C5.71569 5.40973 5.40973 5.71569 5.21799 6.09202C5 6.51984 5 7.07989 5 8.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.07989 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V8.2C19 7.07989 19 6.51984 18.782 6.09202C18.5903 5.71569 18.2843 5.40973 17.908 5.21799C17.5064 5.01338 16.9884 5.00082 16 5.00005M8 5.00005V7H16V5.00005M8 5.00005V4.70711C8 4.25435 8.17986 3.82014 8.5 3.5C8.82014 3.17986 9.25435 3 9.70711 3H14.2929C14.7456 3 15.1799 3.17986 15.5 3.5C15.8201 3.82014 16 4.25435 16 4.70711V5.00005M15 12H12M15 16H12M9 12H9.01M9 16H9.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            {/* Examples heading */}
            <h3>Examples</h3>
          </div>

          {/* HeroInfo component to display examples */}
          <HeroInfo info={"What is the deduction amount in 80C?"}/>
          <HeroInfo info={"What is the purpose of notification under clause (23FE) of section 10?"} />
        </div>

        {/* Section for Capabilities */}
        <div className="info-sub-container">
          <div className="info-heading">
            {/* SVG icon for Capabilities */}
            <svg className="hero-svg" width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 11L10 16H15L12 21M6 16.4438C4.22194 15.5683 3 13.7502 3 11.6493C3 9.20008 4.8 6.9375 7.5 6.5C8.34694 4.48637 10.3514 3 12.6893 3C15.684 3 18.1317 5.32251 18.3 8.25C19.8893 8.94488 21 10.6503 21 12.4969C21 14.0582 20.206 15.4339 19 16.2417" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            {/* Capabilities heading */}
            <h3>Capabilities</h3>
          </div>

          {/* HeroInfo component to display capabilities */}
          <HeroInfo info={"An AI chatbot coluped with all Incometax related content"} />
          <HeroInfo info={"Understands general language and generates respone as asked in the question"}/>
        </div>

        {/* Section for Limitations */}
        <div className="info-sub-container side-container">
          <div className="info-heading">
            {/* SVG icon for Limitations */}
            <svg className="hero-svg" width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M12 16.99V17M12 7V14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="none"/>
            </svg>

            {/* Limitations heading */}
            <h3>Cautions</h3>
          </div>

          {/* HeroInfo component to display Limitations */}
          <HeroInfo info={"Might generate incorrect information sometimes"} />
          <HeroInfo info={"Sometimes answers very hard question and sometimes fails to answer easy ones"} />
        </div>
      </div>
    </div>
  );
}

export default Hero;