import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ChatInputForm from "../Components/ChatInputForm";
import ChatMessage from "../Components/ChatMessage";
import NewChatButton from "../Components/NewChatButton";
import Hero from "../Components/Hero";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";
import MessageHistory from "../Components/MessageHistory";
import SuggestionModal from "../Components/SuggestionModal";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  getRedirectResult,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
//import { collection, getDocs, getFirestore } from "firebase/firestore";
//import { ref, push, get, set, update, increment } from "firebase/database";
import SuggestionButton from "../Components/SuggestionButton";
import "./MainPage.css";
import Disclaimer from "../Components/Disclaimer";
import TemperatureSlider from "../Components/TemperatureSlider";
//import SignUpHeading from "../Components/SignUpHeading";
//import NotSubscribedHeading from "../Components/NotSubscribedHeading";
//import FreeTrial from "../Components/FreeTrial";
import ClearConversations from "../Components/ClearConversations";
import RateLimitError from "../Components/RateLimitError";
import ResponseFailedError from "../Components/ResponseFailedError";

function MainPage({ app}) {
  const provider = new GoogleAuthProvider(app);
  provider.setCustomParameters({
    prompt: 'select_account'
  });
  const auth = getAuth(app);
  //const firestoreDB = getFirestore(app);

  const [user, setUser] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [hasTrial, setHasTrial] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [responseFailed, setResponseFailed] = useState(false);
  const [selectedModel, setSelectedModel] = useState("gpt-3.5-turbo");
  const [messageHistory, setMessageHistory] = useState([]);

  function handleNewChat() {
    if (user) {
      setConversationId(user.uid + Date.now());
    }
    clearInput();
    clearChat();
    setIsRateLimited(false);
    setResponseFailed(false);
  }

  // Redirect user to google login
  async function handleLogin() {
    setIsLoggingIn(true);
    await signInWithRedirect(auth, provider).then(()=>{
      console.log("redirected");
    }).catch((err)=>{
      console.log(err.message);
    });
    // getRedirectResult(auth)
    //   .then(() => {
    //     console.log("Signed In");
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //   });
  }

  // Log user out, clear old messages and input
  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Logged out");
        clearInput();
        clearChat();
        setIsLoading(false);
        setIsRateLimited(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Check if a user has had a free trial and create one in database if they haven't
  // const handleTrialPeriod = (user) => {
  //   const userRef = ref(db, `trials/${user.uid}`);

  //   get(userRef).then((snapshot) => {
  //     if (snapshot.val() === null) {
  //       // create a new user document with the createdAt timestamp
  //       set(userRef, {
  //         createdAt: Date.now(),
  //       })
  //         .then(() => {
  //           console.log("Trial Started");
  //           checkTrialExpired(user);
  //         })
  //         .catch((error) => {
  //           console.log("The write failed...", error);
  //         });
  //     }
  //   });
  // };

  // Checks if user has free trial time
  // const checkTrialExpired = async (user) => {
  //   if (!user) return;

  //   const userRef = ref(db, `trials/${user.uid}`);

  //   get(userRef).then((snapshot) => {
  //     if (snapshot.val() === null) return;

  //     const trialData = snapshot.val();
  //     const createdAt = trialData.createdAt;
  //     const now = Date.now();

  //     const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  //     const trialDuration = now - createdAt;

  //     // Return true if the trial has not yet expired (is less than one day old)
  //     if (trialDuration < oneDayInMilliseconds) {
  //       setHasTrial(true);
  //     }
  //   });
  // };

  

  useEffect(() => {
    // When the authentication state changes (user logging in/out)
    onAuthStateChanged(auth, (currentUser) => {
      setAuthLoading(false);
      // Update user
      if(!user){
        setUser(currentUser);
      }
      
      // Give new conversation id
      //setConversationId(user.uid + Date.now());
      // Load users sub data
      //loadSubscription();
      // Create free trial if user has never had one
      //handleTrialPeriod(user);
      // Check if free trial is still active
      //checkTrialExpired(user);
      
      if(currentUser){
        const isUserRegistered = localStorage.getItem('isUserRegisteredWithId'+currentUser.uid);
        console.log(isUserRegistered)
        if(!isUserRegistered){
          fetch(
            "https://api.iova.app/api/llm/addLlmUser",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${currentUser.accessToken}`
              },
              // Post the last 6 messages (including the ai responses)
              // and the temperature for the current prompt
              body: JSON.stringify({
                emailId: currentUser.email,
                firebaseUserID: currentUser.uid
              }),
            }
          )
          .then(resp =>resp.json())
          .then(data => {
            console.log(data);
            localStorage.setItem('isUserRegisteredWithId'+currentUser.uid, true);
          })
          .catch((error) => {
            // Show response failed error
            console.log(error);
          });
        }
      }
    });
  }, []);

  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([/*{user: "me", message: 'wqUKTYGFUYGF UVITYFV TUYF ITF FITF KTF KYTTF KUFKUF VKUTFV KUVF KUFVKUFG KUY Keqweqwqwe'},{user: "gpt", message: 'wqeqweqwqwe'},{user: "me", message: 'wqeqweqwqwe'}, {user: "gpt", message: 'wqeqweqwqwe dfbgxd ndngf fghnfd drtfghfgh rdtyjhdfghf drfthfdg dghfghfdg dhjgdhjgfhj dyjfgjfghj gthjghjg ftyjghjgfhj fghjgf'},{user: "me", message: 'wqUKTYGFUYGF UVITYFV TUYF ITF FITF KTF KYTTF KUFKUF VKUTFV KUVF KUFVKUFG KUY Keqweqwqwe'},{user: "gpt", message: 'wqeqweqwqwe'},{user: "me", message: 'wqeqweqwqwe'}, {user: "gpt", message: 'wqeqweqwqwe dfbgxd ndngf fghnfd drtfghfgh rdtyjhdfghf drfthfdg dghfghfdg dhjgdhjgfhj dyjfgjfghj gthjghjg ftyjghjgfhj fghjgf'},{user: "me", message: 'wqUKTYGFUYGF UVITYFV TUYF ITF FITF KTF KYTTF KUFKUF VKUTFV KUVF KUFVKUFG KUY Keqweqwqwe'},{user: "gpt", message: 'wqeqweqwqwe'},{user: "me", message: 'wqeqweqwqwe'}, {user: "gpt", message: 'wqeqweqwqwe dfbgxd ndngf fghnfd drtfghfgh rdtyjhdfghf drfthfdg dghfghfdg dhjgdhjgfhj dyjfgjfghj gthjghjg ftyjghjgfhj fghjgf'},{user: "me", message: 'wqUKTYGFUYGF UVITYFV TUYF ITF FITF KTF KYTTF KUFKUF VKUTFV KUVF KUFVKUFG KUY Keqweqwqwe'},{user: "gpt", message: 'wqeqweqwqwe'},{user: "me", message: 'wqeqweqwqwe'}, {user: "gpt", message: 'wqeqweqwqwe dfbgxd ndngf fghnfd drtfghfgh rdtyjhdfghf drfthfdg dghfghfdg dhjgdhjgfhj dyjfgjfghj gthjghjg ftyjghjgfhj fghjgf'}*/]);
  const [isLoading, setIsLoading] = useState(null);

  function clearChat() {
    setChatLog([]);
  }

  function clearInput() {
    setInput("");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];

    clearInput(); // Clear the input field
    setChatLog(chatLogNew); // Set the new chat log with user's input
    setIsLoading(true);
    
    setIsRateLimited(false);
    setResponseFailed(false);
    // Fetch response from the backend
    const response = await fetch(
      "https://api.iova.app/api/llm/addQuestion",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.accessToken}`
          },
          body: JSON.stringify({
            emailId: user.email,
            question: input
          }),
        }
      )
      .catch((error) => {
        console.log(error);
      });
    // If user hit rate limit display error
    if (response.status === 429) {
      setIsRateLimited(true);
    }
    else if(response.status === 200){
      setIsRateLimited(false);
      setResponseFailed(false);
      const responseJSON = await response.json();
      setChatLog([...chatLogNew, { user: "gpt", message: `${responseJSON.data.response}` }]);
      setMessageHistory([{question: input, promptResponse: `${responseJSON.data.response}`}].concat(messageHistory));
    }
    else if(response.status === 400){
      setResponseFailed(true);
    }
    else if(response.status === 424 || response.status === 500){
      setResponseFailed(true);
    }
    setIsLoading(false);
  }

  // Get users subscription data
  // const loadSubscription = async () => {
  //   // Get the current user
  //   const sub = auth.currentUser;
  //   // Get the reference to the user's subscriptions
  //   const ref = await getDocs(
  //     collection(firestoreDB, `customers/${sub.uid}/subscriptions`)
  //   );

  //   // Set subscription details in state
  //   ref.forEach(async (doc) => {
  //     setSubscription({
  //       role: doc.data().role,
  //       current_period_end: doc.data().current_period_end,
  //       current_period_start: doc.data().current_period_start,
  //       ended_at: doc.data().ended_at,
  //       status: doc.data().status,
  //     });
  //   });
  // };
  
  return (
    <div className="App">
      {/* Side menu (not visible on mobile) */}
      <aside className="side-menu">
        {/* <NewChatButton handleNewChat={handleNewChat} isLoading={isLoading} /> */}
        {/* Display message history if user is logged in */}
        {user && (
          <MessageHistory
            // emailId=/*{user.email}*/"arora.sten@gmail.com"
            user = {user}
            chatLog = {chatLog}
            setChatLog = {setChatLog}
            messageHistory = {messageHistory}
            setMessageHistory = {setMessageHistory}
          />
        )}

        <div className="white-buttons">
          <ClearConversations user={user} />
          <SuggestionModal user={user} />
        </div>

        {/* Displays users free trial status */}
        {/* {hasTrial && subscription?.role !== "premium" && user && <FreeTrial />} */}

        {/* Takes user to the store, only displayed if user is logged in */}
        {/* <SuggestionButton user={user} /> */}

        {/* Login/logout buttons */}
        {!authLoading && (
          <GoogleAuthButtons
            user={user}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        )}
      </aside>

      {/* Chat box */}
      <section className="chat-box">
        {/* New chat button for mobile only */}
        <div className="mobile-new-chat-button">
          <NewChatButton isLoading={isLoading} handleNewChat={handleNewChat} />
        </div>

        {/* Only display hero if theres no chats and if user isn't rate limited */}
        { chatLog.length === 0 && !isRateLimited && <Hero /> }

        {/* Store button for mobile only */}
        {/* <div className="mobile-store-button">
          {chatLog.length === 0 && <StoreButton user={user} />}
        </div> */}

        {/* Chat log (the actual messages) */}
        {/* {!isRateLimited && (
          <div className="chat-log">
            {chatLog.map((message, index) => (
              <ChatMessage
                key={index}
                message={message}
                isLastMessage={index === chatLog.length - 1}
                selectedModel={selectedModel}
              />
            ))}
            {isLoading === true && (
              <div className="circular-progress">
                <CircularProgress style={{ color: "white" }} />
              </div>
            )}
          </div>
        )} */}

        {/* Error messages */}
        {/* {isRateLimited && <RateLimitError />}
        {responseFailed && <ResponseFailedError />} */}

        {/* 
          Login button for mobile only 
          Right now its set up to only display the login button need to rework
          the ui for mobile 
        */}
        {/* {!user && !authLoading && (
          <div className="mobile-login-button">
            <GoogleAuthButtons
              user={user}
              handleLogin={handleLogin}
              handleLogout={handleLogout}
            />
          </div>
        )} */}

        {/* If user is subscribed or has trial display temperature slider */}
        {/* {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          selectedModel === "gpt-3.5-turbo" &&
          subscription?.status !== "incomplete") ? (
          <TemperatureSlider setTemperature={setTemperature} user={user} />
        ) : null} */}

        {/* If the user is not signed in display sign up heading */}
        {/* {!user && !authLoading && (
          <SignUpHeading handleLogin={handleLogin} isLoggingIn={isLoggingIn} />
        )} */}

        {/* If the user isn't subscribed display the visit store heading */}
        {/* {subscription?.role !== "premium" && user && !hasTrial && (
          <NotSubscribedHeading />
        )} */}

        {/* Loading spinner when user is logging in */}
        {authLoading && <CircularProgress style={{ color: "#b3befe" }} />}

        {/* If the user is subscribed or has trial display the chat input form */}
        {/* {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          subscription?.status !== "incomplete") ? (
          <ChatInputForm
            input={input}
            setInput={setInput}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            user={user}
            handleLogin={handleLogin}
            selectedModel={selectedModel}
          />
        ) : null} */}
          {
            

            <div className="main-container">
              {(
                <div className="chat-log">
                  {chatLog.map((message, index) => (
                    <ChatMessage
                      key={index}
                      message={message}
                      isLastMessage={index === chatLog.length - 1}
                      selectedModel={selectedModel}
                    />
                  ))}
                  {isLoading === true && (
                    <div className="circular-progress">
                      <CircularProgress style={{ color: "white" }} />
                    </div>
                  )}
                </div>
              )}
              {isRateLimited && <RateLimitError />}
              {responseFailed && <ResponseFailedError />} 
              <ChatInputForm
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              user={user}
              handleLogin={handleLogin}
            />
            </div>
          }
        <Disclaimer />
      </section>
    </div>
  );
}

export default MainPage;
