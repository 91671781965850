import React, { useState, useEffect } from "react";
import { ref, get, onValue } from "firebase/database";
import { motion } from "framer-motion";
import "./MessageHistory.css";

// TODO: Allow user to view full conversation when they click a message in history

// Displays first message of each conversation
function MessageHistory({ user, chatLog, setChatLog, messageHistory, setMessageHistory }) {

  const [historyLoading, sethistoryLoading] = useState(true);
  // Fetch first message of each conversation
  useEffect(() => {
    // only run if user is logged in
    if (user) {
      fetch(
        "https://api.iova.app/api/llm/getQuestionHistory?emailId=" + user.email,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.accessToken}`
          },
        }
      )
      .then(resp =>resp.json())
      .then(data => {
        console.log(data);
        setMessageHistory(data.data);
        sethistoryLoading(false);
      })
      .catch((error) => {
        // Show response failed error
        console.log(error);
      });
    }
  }, []);

  const handleClick = (message) => {
    // update the items array
    const newItems = [...chatLog, {user: "me", message: message.question}, {user: "gpt", message: message.promptResponse}];
    setChatLog(newItems);
  };

  return (
    <div className="message-history-container">
      {/*Loop through the message history */}
      {!historyLoading && messageHistory?.map((message, index) => (
        // Display the first message for each conversation
        <button className="message-button" key={index} onClick={() => handleClick(message)}>
          <motion.div
            // fade messages in
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            className="message-container"
          >
            {/* Chat bubble svg */}
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="message-svg"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
            </svg>

            {/* Truncated message */}
            <p className="truncate-text">
              {/* {message.user === "me" &&
                // Uppercase first character of message
                message.message.charAt(0).toUpperCase() +
                  message.message.slice(1)} */}
              {message.question}
            </p>
          </motion.div>
        </button>
      ))}
    </div>
  );
}

export default MessageHistory;
